import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';

interface Props extends ChakraButtonProps {
  label: string;
}

export default function ConfirmButton(props: Props) {
  const { label } = props;

  return (
    <ChakraButton
      w={['full', 'full', 'fit-content']}
      textAlign={'center'}
      pr={'50px'}
      pl={'50px'}
      bgColor={'coap.yellow.1'}
      fontWeight={'semibold'}
      borderRadius={'1000px'}
      _hover={{ opacity: 0.8 }}
      _active={{ opacity: 0.9 }}
      {...props}
    >
      {label}
    </ChakraButton>
  );
}
