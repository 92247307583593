import { useCallback } from 'react';
import {
  Container,
  Text,
  Center,
  HStack,
  VStack,
  // Divider,
  Box,
  // Input,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Show,
  // FormControl,
  // FormErrorMessage,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { AsyncState } from '../../types/state';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import useAppSelector from '../../redux/hooks/useAppSelector';
import * as claimThunk from '../../redux/thunks/claim';
import Header from '../../components/Header';
import ConfirmButton from '../../components/ConfirmButton';
import RocketImg from '../../images/rocket.png';
import Exception from '../../containers/Exception';

export default function Onboard() {
  const dispatch = useAppDispatch();
  const confirmClaimStatus = useAppSelector(
    (state) => state.claim.confirmClaimStatus
  );
  const confirmClaimException = useAppSelector(
    (state) => state.claim.confirmClaimException
  );
  const campaign = useAppSelector((state) => state.campaign.campaign);
  const otpToken = useAppSelector((state) => state.claim.confirmOtp?.token);
  const navigate = useNavigate();

  const handleClaim = useCallback(async () => {
    await dispatch(
      claimThunk.confirmClaim({ token: otpToken as string })
    ).unwrap();
    navigate('/success', { replace: true });
  }, [dispatch, navigate, otpToken]);

  const _formik = useFormik({
    initialValues: {
      address: '',
    },
    onSubmit: async ({ address }, { setErrors }) => {
      try {
        await dispatch(
          claimThunk.confirmClaim({ token: otpToken as string, address })
        ).unwrap();
        navigate('/success', { replace: true });
      } catch (e) {
        if (e === 40000) {
          setErrors({ address: 'Invalid wallet address' });
        }
      }
    },
  });

  if (!campaign) {
    return <Exception code={40004} message={'Campaign Not Found'} />;
  }

  if (confirmClaimException) {
    return (
      <Exception
        code={confirmClaimException.code}
        message={confirmClaimException.msg}
      />
    );
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <Header campaign={campaign} />
      <Center my={'50px'}>
        <Text fontWeight={'700'} fontSize={'xl'}>
          First time to claim COAP token?
        </Text>
      </Center>
      <Show below={'sm'}>
        <Center>
          <Accordion allowToggle w={'95%'}>
            <AccordionItem borderTop={'none'}>
              <AccordionButton
                justifyContent={'space-between'}
                _expanded={{
                  bgColor: 'coap.blue.3',
                  color: '#ffffff',
                  fontWeight: '700',
                }}
              >
                <Text>Create web3 wallet automatically</Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Text fontWeight={'300'} fontSize={'sm'} mb={'20px'}>
                  We will create a Web3 wallet for you to claim the token.
                </Text>
                <Center my={'10px'}>
                  <Image h={'150px'} src={RocketImg} loading={'lazy'} />
                </Center>
                <ConfirmButton
                  label={'Claim'}
                  onClick={handleClaim}
                  isLoading={confirmClaimStatus === AsyncState.PENDING}
                />
              </AccordionPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionButton
                justifyContent={'space-between'}
                _expanded={{
                  bgColor: 'coap.blue.3',
                  color: '#ffffff',
                  fontWeight: '700',
                }}
              >
                <Text>Already have a web3 wallet</Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Text fontWeight={'300'} fontSize={'sm'} mb={'20px'}>
                  Provide your Web3 wallet address to claim the token.
                </Text>
                <form onSubmit={formik.handleSubmit}>
                  <FormControl isInvalid={!!formik.errors.address} mb={'20px'}>
                    <Input
                      name={'address'}
                      size={'sm'}
                      borderRadius={0}
                      placeholder={'Wallet Address'}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
                  </FormControl>
                  <Button
                    type={'submit'}
                    size={'sm'}
                    w={'100%'}
                    bgColor={'coap.yellow.1'}
                    _hover={{ bgColor: 'coap.yellow.1' }}
                    isLoading={confirmClaimStatus === AsyncState.PENDING}
                  >
                    Claim
                  </Button>
                </form>
              </AccordionPanel>
            </AccordionItem> */}
          </Accordion>
        </Center>
      </Show>

      <Show above={'sm'}>
        <Center p={'20px'}>
          <HStack alignItems={'start'} gap={'25px'}>
            <Box textAlign={'center'}>
              <Text fontSize={'lg'} fontWeight={'700'}>
                Create Web3 wallet automatically
              </Text>
              <Center>
                <Image height={'200px'} src={RocketImg} loading={'lazy'} />
              </Center>
              <VStack h={'80px'} justifyContent={'center'}>
                <Text fontWeight={'300'}>
                  We will create a Web3 wallet for you to claim the token
                </Text>
              </VStack>
              <ConfirmButton
                label={'Claim'}
                onClick={handleClaim}
                isLoading={confirmClaimStatus === AsyncState.PENDING}
              />
            </Box>
            {/* <Divider
              orientation={'vertical'}
              h={'200px'}
              alignSelf={'center'}
            />
            <Box textAlign={'center'}>
              <Text fontSize={'lg'} fontWeight={'700'}>
                Already have a Web3 account?
              </Text>
              <Center h={'200px'}>
                <Text fontWeight={'300'}>
                  Provide your Web3 wallet address to claim the token
                </Text>
              </Center>
              <form onSubmit={formik.handleSubmit}>
                <VStack
                  h={'80px'}
                  justifyContent={'center'}
                  alignItems={'start'}
                  spacing={0}
                >
                  <Text textAlign={'start'} fontWeight={'400'}>
                    Wallet address
                  </Text>
                  <FormControl isInvalid={!!formik.errors.address}>
                    <Input
                      name={'address'}
                      size={'sm'}
                      borderRadius={0}
                      placeholder={'Wallet Address'}
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    <FormErrorMessage color={'red'}>
                      Invalid wallet address.
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
                <Button
                  type={'submit'}
                  size={'sm'}
                  bgColor={'coap.yellow.1'}
                  _hover={{ bgColor: 'coap.yellow.1' }}
                  isLoading={confirmClaimStatus === AsyncState.PENDING}
                  onClick={handleClaim}
                >
                  Claim
                </Button>
              </form>
            </Box> */}
          </HStack>
        </Center>
      </Show>
    </Container>
  );
}
