import claimService from './services/claim';
import type * as Entity from './../types/entity';
import { ApiException } from './exceptions';
import { AxiosError } from 'axios';

export const getCampaignById = async (id: string): Promise<Entity.Campaign> => {
  try {
    const { data } = await claimService.get<Entity.Campaign>(
      `/v1/campaigns/${id}`
    );
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};
