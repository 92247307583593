import claimService from './services/claim';
import type * as Param from '../types/param';
import type * as Entity from '../types/entity';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiException } from './exceptions';

export const claimMintLink = async (
  args: Param.Claim
): Promise<Entity.MintLink> => {
  try {
    const { data } = await claimService.post<Entity.MintLink>(
      '/v1/claim/_mint_link',
      args
    );
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};

export const claimByDispenser = async (
  args: Param.Claim
): Promise<AxiosResponse<Entity.Dispenser, Entity.Dispenser>> => {
  return claimService.post('/v1/claim/_dispenser', args);
};

export const confirmOtp = async (
  args: Param.ConfirmOtp
): Promise<Entity.ConfirmOtp> => {
  try {
    const { data } = await claimService.post<Entity.ConfirmOtp>(
      '/v1/claim/_confirm_otp',
      args
    );
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};

export const confirmClaim = async (
  args: Param.ConfirmClaim
): Promise<Entity.ConfirmClaim> => {
  try {
    const { data } = await claimService.post<Entity.ConfirmClaim>(
      '/v1/claim/_confirm_claim',
      args
    );
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};
