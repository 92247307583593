import { configureStore } from '@reduxjs/toolkit';
import config from '../config';
import claimReducer from './reducers/claim';
import campaignReducer from './reducers/campaign';

export const store = configureStore({
  devTools: config.env === 'development',
  reducer: {
    claim: claimReducer,
    campaign: campaignReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
