import { createReducer } from '@reduxjs/toolkit';
import { AsyncState } from '../../types/state';
import * as Entity from '../../types/entity';
import * as claimThunk from '../thunks/claim';
import * as claimAction from '../actions/claim';

interface ClaimState {
  returnUrl: string | null;
  mintLinkData: Entity.MintLinkData | null;
  emailAddr: string | null;
  claimStatus: AsyncState;
  claimException: Entity.Exception | undefined;
  confirmOtp: Entity.ConfirmOtp | null;
  confirmOtpStatus: AsyncState;
  confirmOtpException: Entity.Exception | undefined;
  confirmClaim: Entity.ConfirmClaim | null;
  confirmClaimException: Entity.Exception | undefined;
  confirmClaimStatus: AsyncState;
}

const initialState: ClaimState = {
  returnUrl: null,
  mintLinkData: null,
  emailAddr: null,
  claimStatus: AsyncState.IDLE,
  claimException: undefined,
  confirmClaim: null,
  confirmClaimStatus: AsyncState.IDLE,
  confirmClaimException: undefined,
  confirmOtp: null,
  confirmOtpException: undefined,
  confirmOtpStatus: AsyncState.IDLE,
};

const claimReducer = createReducer(initialState, (builder) => {
  builder.addCase(claimThunk.claimMintLink.pending, (state) => {
    state.claimStatus = AsyncState.PENDING;
  });

  builder.addCase(claimThunk.claimMintLink.fulfilled, (state, { payload }) => {
    state.emailAddr = payload;
    state.claimStatus = AsyncState.SUCCESS;
  });

  builder.addCase(claimThunk.claimMintLink.rejected, (state, { payload }) => {
    state.claimStatus = AsyncState.REJECTED;
    state.claimException = payload;
  });

  builder.addCase(claimThunk.claimByDispenser.pending, (state) => {
    state.claimStatus = AsyncState.PENDING;
  });

  builder.addCase(
    claimThunk.claimByDispenser.fulfilled,
    (state, { payload }) => {
      state.emailAddr = payload;
      state.claimStatus = AsyncState.SUCCESS;
    }
  );

  builder.addCase(
    claimThunk.claimByDispenser.rejected,
    (state, { payload }) => {
      state.claimException = payload;
      state.claimStatus = AsyncState.REJECTED;
    }
  );

  builder.addCase(claimThunk.confirmClaim.pending, (state) => {
    state.confirmClaimStatus = AsyncState.PENDING;
  });

  builder.addCase(claimThunk.confirmClaim.fulfilled, (state, { payload }) => {
    state.confirmClaim = payload;
    state.confirmClaimStatus = AsyncState.SUCCESS;
  });

  builder.addCase(claimThunk.confirmClaim.rejected, (state, { payload }) => {
    state.confirmClaimStatus = AsyncState.REJECTED;
    state.confirmClaimException = payload;
  });

  builder.addCase(claimThunk.confirmOtp.pending, (state) => {
    state.confirmOtpStatus = AsyncState.PENDING;
  });

  builder.addCase(claimThunk.confirmOtp.fulfilled, (state, { payload }) => {
    state.confirmOtp = payload;
    state.confirmOtpStatus = AsyncState.SUCCESS;
  });

  builder.addCase(claimThunk.confirmOtp.rejected, (state, { payload }) => {
    state.confirmOtpException = payload;
    state.confirmOtpStatus = AsyncState.REJECTED;
  });

  builder.addCase(claimAction.storeMintLinkData, (state, { payload }) => {
    state.mintLinkData = payload;
  });

  builder.addCase(claimAction.storeReturnUrl, (state, { payload }) => {
    state.returnUrl = payload;
  });
});

export default claimReducer;
