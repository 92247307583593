import { createReducer } from '@reduxjs/toolkit';
import { AsyncState } from '../../types/state';
import * as Entity from '../../types/entity';
import * as campaignThunk from '../thunks/campaign';

interface CampaignState {
  campaign: Entity.Campaign | null;
  getCampaignByIdStatus: AsyncState;
  getCampaignByIdException: Entity.Exception | undefined;
}

const initialState: CampaignState = {
  campaign: null,
  getCampaignByIdStatus: AsyncState.IDLE,
  getCampaignByIdException: undefined,
};

const campaignReducer = createReducer(initialState, (builder) => {
  builder.addCase(campaignThunk.getCampaignById.pending, (state) => {
    state.getCampaignByIdStatus = AsyncState.PENDING;
  });

  builder.addCase(
    campaignThunk.getCampaignById.fulfilled,
    (state, { payload }) => {
      state.campaign = payload;
      state.getCampaignByIdStatus = AsyncState.SUCCESS;
    }
  );

  builder.addCase(
    campaignThunk.getCampaignById.rejected,
    (state, { payload }) => {
      state.getCampaignByIdException = payload;
      state.getCampaignByIdStatus = AsyncState.REJECTED;
    }
  );
});

export default campaignReducer;
