import { env } from '../env';
const config = {
  nodeEnv: env.NODE_ENV || 'development',
  env: env.REACT_APP_ENV || 'development',
  build: env.REACT_APP_BUILD || 'local',
  serviceUrl: {
    claim: env.REACT_APP_CLAIM_SERVICE_URL || 'https://dev-api.coap.app/claim',
  },
  webUrl: {
    myCoap: env.REACT_APP_MYCOAP_WEB_URL || '#',
  },
};

export default config;
