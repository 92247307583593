import createAppAsyncThunk from '../helpers/createAppAsyncThunk';
import { ApiException } from '../../apis/exceptions';
import * as claimApi from '../../apis/claim';
import type * as Param from '../../types/param';

export const claimMintLink = createAppAsyncThunk(
  'claim/claimMintLink',
  async (args: Param.Claim, store) => {
    try {
      const { code } = await claimApi.claimMintLink(args);

      if (code) {
        console.log('[DEBUG] OTP CODE: ', code);
      }

      return args.e; //email
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown API exception',
        code: 0,
      });
    }
  }
);

export const confirmOtp = createAppAsyncThunk(
  'claim/confirmOtp',
  async (args: Param.ConfirmOtp, store) => {
    try {
      return await claimApi.confirmOtp(args);
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown API exception',
        code: 0,
      });
    }
  }
);

export const confirmClaim = createAppAsyncThunk(
  'claim/confirmClaim',
  async (args: Param.ConfirmClaim, store) => {
    try {
      return await claimApi.confirmClaim(args);
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown API exception',
        code: 0,
      });
    }
  }
);

export const claimByDispenser = createAppAsyncThunk(
  'claim/claimByDispenser',
  async (args: Param.Claim, store) => {
    try {
      const { data } = await claimApi.claimByDispenser(args);
      const { code } = data;

      if (code) {
        console.log('[DEBUG] OTP CODE: ', code);
      }

      return args.e;
    } catch (e: any) {
      return store.rejectWithValue({
        msg: e.response.data.msg,
        code: e.response.data.code,
      });
    }
  }
);
