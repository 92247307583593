import { Routes, Route } from 'react-router-dom';
import Claim from './pages/Claim';
import Success from './pages/Success';
import Onboard from './pages/Onboard';
import Verify from './pages/Verify';
import NotFound404 from './pages/404';

function App() {
  return (
    <Routes>
      <Route path={'/claim'} element={<Claim />} />
      <Route path={'/success'} element={<Success />} />
      <Route path={'/onboard'} element={<Onboard />} />
      <Route path={'/verify'} element={<Verify />} />
      <Route path={'*'} element={<NotFound404 />} />
    </Routes>
  );
}

export default App;
