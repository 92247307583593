import { useMemo } from 'react';
import { Container, Flex, Text, Box } from '@chakra-ui/react';
import config from '../../config';
import Header from '../../components/Header';
import ConfirmButton from '../../components/ConfirmButton';
import useAppSelector from '../../redux/hooks/useAppSelector';

interface Props {
  code: number;
  message: string;
}

export default function Exception(props: Props) {
  const { code, message } = props;
  const campaign = useAppSelector((state) => state.campaign.campaign);

  const codeToMsg = useMemo<Record<number, string>>(() => {
    return {
      40000: 'Invalid Input',
      40001: 'Unauthorized',
      40004: 'Resource Not Found',
      99999: 'Service unavailable',
    };
  }, []);

  return (
    <Container maxW={'2560px'} p={0}>
      <Header campaign={campaign} />
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        h={'calc(100vh - 200px)'}
        px={'20px'}
      >
        <Box textAlign={'center'}>
          <Text fontSize={'2xl'} fontWeight={'600'} mb={'20px'}>
            {codeToMsg[code]}
          </Text>
          <Text fontSize={'lg'} mb={'20px'}>
            {message}
          </Text>
          <ConfirmButton
            label={'Back to My COAP'}
            onClick={() => window.location.replace(config.webUrl.myCoap)}
          />
        </Box>
      </Flex>
    </Container>
  );
}
