export interface MintLinkData {
  c: string;
  t: string;
  s: string;
  e: string;
}

export interface DispenserData {
  c: string;
  t: string;
  s: string;
  e: string;
  ch: string;
}

export interface Exception {
  msg: string;
  code: number;
}

export interface MintLink {
  created_at?: string;
  updated_at?: string;
  email?: string;
  code?: string;
  expired_at?: string;
  data?: MintLinkData;
}

export interface Dispenser {
  created_at?: string;
  updated_at?: string;
  email?: string;
  code?: string;
  expired_at?: string;
  data: DispenserData;
}

export interface ConfirmOtp {
  is_coap_user: boolean;
  token: string;
}

export interface ConfirmClaim {
  created_at: string;
  updated_at: string;
  id: string;
  user_id: string;
  account_id: string;
  campaign_id: string;
  token_id: number;
  image_uri: string;
}

export interface File {
  id: string;
  display_name: string;
  content_type: string;
  size: number;
  uri: string;
}

export enum CampaignType {
  UNKNOWN = 'unknown',
  VOUCHER = 'voucher',
  MEMBERSHIP = 'membership',
  BADGE = 'badge',
  ATTENDANCE = 'attendance',
  SKILL = 'skill',
  ACADEMIC = 'academic',
}

export enum CampaignStatus {
  PENDING = 'pending',
  PROCEEDING = 'proceeding',
  FINISHED = 'finished',
  FAILED = 'failed',
  PUBLISHED = 'published',
}
export interface CampaignInfo {
  title: string;
  description: string;
  content: string;
}

export interface TokenAttribute {
  trait_type: string;
  value: string;
}

export interface TokenInfo {
  token_id: string;
  token_name: string;
  token_attributes: TokenAttribute[];
  token_description: string;
  token_file_id: string;
  file: File | null;
}

export interface IssueInfo {
  num_of_mint_link: number;
  claimed_count: number;
}

export interface IssuerInfo {
  description: string;
  logo_uri: string;
  name: string;
  website: string;
}

export interface CampaignPeriod {
  start: string;
  end: string;
}

export interface Campaign {
  id: string;
  number_of_issue: number;
  token_uri: string;
  created_at: string;
  issued_at: string;
  published: boolean;
  usage_limit: number;
  type: CampaignType;
  status: CampaignStatus;
  campaign_info: CampaignInfo;
  claim_period: CampaignPeriod;
  valid_period: CampaignPeriod;
  issue_info: IssueInfo;
  issuer_info: IssuerInfo;
  token_info: TokenInfo;
}

export enum Channel {
  DISPENSER = 'dispenser',
}
