import { Container, Flex, Text, Box, Button } from '@chakra-ui/react';
import config from '../../config';
import useAppSelector from '../../redux/hooks/useAppSelector';
import Header from '../../components/Header';

export default function NotFound404() {
  const campaign = useAppSelector((state) => state.campaign.campaign);

  return (
    <Container maxW={'2560px'} p={0}>
      <Header campaign={campaign} />
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        h={'calc(100vh - 200px)'}
      >
        <Box textAlign={'center'}>
          <Text fontSize={'2xl'} fontWeight={'600'} mb={'20px'}>
            404 Not Found
          </Text>
          <Text fontSize={'lg'} mb={'20px'}>
            We cannot seem to find this URL. Please check your request URL.
          </Text>
          <Button
            borderRadius={'1000px'}
            fontWeight={'semibold'}
            bgColor={'coap.yellow.1'}
            _hover={{
              bgColor: 'coap.yellow.1',
            }}
            onClick={() => window.location.replace(config.webUrl.myCoap)}
          >
            Back to My COAP
          </Button>
        </Box>
      </Flex>
    </Container>
  );
}
