import createAppAsyncThunk from '../helpers/createAppAsyncThunk';
import * as campaignApi from '../../apis/campaign';
import { ApiException } from '../../apis/exceptions';

export const getCampaignById = createAppAsyncThunk(
  'campaign/getCampaignById',
  async (id: string, store) => {
    try {
      return await campaignApi.getCampaignById(id);
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown API exception',
        code: 0,
      });
    }
  }
);
