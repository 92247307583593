import { Box, Image, Text } from '@chakra-ui/react';
import type * as Entity from '../../types/entity';

interface Props {
  campaign: Entity.Campaign | null;
}

export default function Header(props: Props) {
  const { campaign } = props;

  return (
    <Box
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      w={'100%'}
      h={'100px'}
      p={'20px'}
      bgColor={'coap.blue.5'}
      userSelect={'none'}
      color={'#ffffff'}
    >
      <Box hidden={!campaign?.campaign_info.title}>
        <Text fontSize={['lg', 'xl', '2xl']} fontWeight={'semibold'}>
          {campaign?.campaign_info.title}
        </Text>
        <Text fontSize={['sm', 'md', 'lg']} fontWeight={'normal'}>
          Claim Your COAP Token (New-NFT)
        </Text>
      </Box>
      <Image
        hidden={!campaign?.issuer_info.logo_uri}
        w={'100px'}
        h={'100px'}
        objectFit={'scale-down'}
        src={campaign?.issuer_info.logo_uri}
        draggable={false}
        bgColor={'#ffffff'}
        border={'0.1px solid lightgrey'}
      />
    </Box>
  );
}
