import { Container, Box, Center, Image, Text } from '@chakra-ui/react';
import config from '../../config';
import useAppSelector from '../../redux/hooks/useAppSelector';
import Header from '../../components/Header';
import ConfirmButton from '../../components/ConfirmButton';
import CongratImg from '../../images/congratulations.png';
import Exception from '../../containers/Exception';

export default function Success() {
  const campaign = useAppSelector((state) => state.campaign.campaign);

  if (!campaign) {
    return <Exception code={40004} message={'Campaign Not Found'} />;
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <Header campaign={campaign} />
      <Center px={'20px'}>
        <Box textAlign={'center'} userSelect={'none'}>
          <Center my={'50px'}>
            <Image src={CongratImg} height={'150px'} />
          </Center>
          <Text fontSize={'2xl'} fontWeight={'600'} mb={'20px'}>
            Your COAP Token has been claimed successfully
          </Text>
          <Text fontSize={'lg'} mb={'20px'}>
            Please check MyCoap or your wallet to check the token details
          </Text>
          <Center>
            <ConfirmButton
              label={'My COAP'}
              onClick={() => window.location.replace(config.webUrl.myCoap)}
            />
          </Center>
        </Box>
      </Center>
    </Container>
  );
}
