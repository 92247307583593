import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../store';
import { type Exception } from '../../types/entity';

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: Exception;
}>();

export default createAppAsyncThunk;
