import { useCallback } from 'react';
import {
  Container,
  Center,
  Image,
  Text,
  Box,
  HStack,
  useBoolean,
  Spinner,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { AsyncState } from '../../types/state';
import useAppSelector from '../../redux/hooks/useAppSelector';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import * as claimThunk from '../../redux/thunks/claim';
import CheerImg from '../../images/cheers.png';
import Header from '../../components/Header';
import Exception from '../../containers/Exception';

export default function Verify() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const emailAddr = useAppSelector((state) => state.claim.emailAddr);
  const mintLinkData = useAppSelector((state) => state.claim.mintLinkData);
  const confirmOtpStatus = useAppSelector(
    (state) => state.claim.confirmOtpStatus
  );
  const confirmClaimStatus = useAppSelector(
    (state) => state.claim.confirmClaimStatus
  );
  const campaign = useAppSelector((state) => state.campaign.campaign);
  const confirmOtpException = useAppSelector(
    (state) => state.claim.confirmOtpException
  );
  const confirmClaimException = useAppSelector(
    (state) => state.claim.confirmClaimException
  );
  const [isResend, setIsResend] = useBoolean();

  const handleSubmitOtp = useCallback(
    async (code: string) => {
      const { is_coap_user, token } = await dispatch(
        claimThunk.confirmOtp({ email: emailAddr as string, code })
      ).unwrap();

      if (is_coap_user) {
        await dispatch(claimThunk.confirmClaim({ token })).unwrap();
        navigate('/success', { replace: true });
        return;
      }

      navigate('/onboard', { replace: true });
    },
    [dispatch, emailAddr, navigate]
  );

  const handleResendOtp = useCallback(async () => {
    if (!mintLinkData || !emailAddr) return;

    await dispatch(
      claimThunk.claimMintLink({
        ...mintLinkData,
        e: emailAddr,
      })
    );

    setIsResend.on();
  }, [dispatch, emailAddr, mintLinkData, setIsResend]);

  if (!mintLinkData || !emailAddr || !campaign) {
    return <Exception code={40004} message={'Campaign Not Found'} />;
  }

  if (confirmClaimException) {
    return (
      <Exception
        code={confirmClaimException.code}
        message={confirmClaimException.msg}
      />
    );
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <Header campaign={campaign} />
      <Center mt={'50px'}>
        <Box textAlign={'center'}>
          <Center mb={'20px'}>
            <Image h={'150px'} src={CheerImg} />
          </Center>
          <Text fontSize={'xl'} fontWeight={'700'} mb={'10px'}>
            It's almost done to claim the COAP token.
          </Text>
          <Text fontSize={'lg'} fontWeight={'300'}>
            Check your email inbox for an one time password
          </Text>
          <Text fontSize={'lg'} fontWeight={'300'} mb={'15px'}>
            Enter the one time password below
          </Text>
          <Center mb={'25px'}>
            <HStack
              position={'relative'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <PinInput
                size={'lg'}
                otp
                onComplete={handleSubmitOtp}
                isDisabled={
                  confirmOtpStatus === AsyncState.PENDING ||
                  confirmClaimStatus === AsyncState.PENDING
                }
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
              <Spinner
                display={
                  confirmOtpStatus === AsyncState.PENDING ||
                  confirmClaimStatus === AsyncState.PENDING
                    ? 'block'
                    : 'none'
                }
                position={'absolute'}
                size="lg"
                zIndex={2}
                color={'coap.blue.5'}
              />
            </HStack>
          </Center>
          {confirmOtpException?.code === 40001 && (
            <Text color={'red'}>Invalid one time password</Text>
          )}
          <Text fontSize={'md'} fontWeight={'300'}>
            <Box as={'span'} mr={'5px'}>
              Did not receive a code?
            </Box>
            {isResend ? (
              <Countdown
                date={Date.now() + 59000}
                renderer={({ seconds }) => (
                  <Text as={'span'}>
                    Wait for{' '}
                    <Text as={'span'} color={'coap.blue.5'}>
                      {seconds}
                    </Text>{' '}
                    to resend OTP again.
                  </Text>
                )}
                onComplete={setIsResend.off}
              />
            ) : (
              <Box
                as="span"
                cursor={'pointer'}
                userSelect={'none'}
                color={'coap.blue.5'}
                onClick={handleResendOtp}
              >
                Resend
              </Box>
            )}
          </Text>
        </Box>
      </Center>
    </Container>
  );
}
